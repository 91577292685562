import adsConstants from '../../utils/adsConstants';
import extractAdId from '../../utils/extractAdId';
import eventBuilder from '../../utils/eventBuilder';
var showcaseProductListImpression = function showcaseProductListImpression() {
  var _window, _window$magaluads;
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref$products = _ref.products,
    products = _ref$products === void 0 ? [] : _ref$products,
    listPosition = _ref.listPosition,
    origin = _ref.origin,
    showcase = _ref.showcase;
  var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    structure = _ref2.structure;
  if (((_window = window) == null ? void 0 : (_window$magaluads = _window.magaluads) == null ? void 0 : _window$magaluads.track) && origin === 'bob') {
    products.forEach(function (product) {
      if (product.trackingUrl) {
        var adId = extractAdId(product.trackingUrl);
        if (adId) {
          var _structure$cookies;
          eventBuilder('Impression', {
            ad_id: adId,
            channel_id: adsConstants.channelId,
            content_id: product.id,
            content_position: product.position,
            module_position: listPosition,
            page_id: showcase.pageId,
            placement_id: showcase.placeId,
            showcase_id: showcase.id,
            user_id: structure == null ? void 0 : (_structure$cookies = structure.cookies) == null ? void 0 : _structure$cookies.customerId
          });
        }
      }
    });
  }
};
export default showcaseProductListImpression;