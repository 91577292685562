import buildSearchEvent from './buildSearchEvent';
var searchProductListClick = function searchProductListClick() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    structure = _ref.structure;
  if (data == null ? void 0 : data.ads) buildSearchEvent({
    eventName: 'Click',
    listPosition: data.listPosition,
    product: data,
    structure: structure
  });
};
export default searchProductListClick;