import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import decodeAdMetadata from './decodeAdMetadata';
var extractAdId = function extractAdId(url) {
  var _url$split = url == null ? void 0 : url.split('?'),
    _url$split2 = _slicedToArray(_url$split, 1),
    trackingUrl = _url$split2[0];
  var adMetadata = trackingUrl == null ? void 0 : trackingUrl.split('/')[8];
  if (adMetadata) {
    return decodeAdMetadata(adMetadata);
  }
  return null;
};
export default extractAdId;