import buildSearchEvent from './buildSearchEvent';
var searchProductListView = function searchProductListView() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    product = _ref.product,
    listPosition = _ref.listPosition;
  var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    structure = _ref2.structure;
  if (product == null ? void 0 : product.ads) {
    buildSearchEvent({
      eventName: 'View',
      listPosition: listPosition,
      product: product,
      structure: structure
    });
  }
};
export default searchProductListView;