import eventBuilder from '../../utils/eventBuilder';
import adsConstants from '../../utils/adsConstants';
var buildSearchEvent = function buildSearchEvent(_ref) {
  var _product$ads, _structure$cookies;
  var eventName = _ref.eventName,
    product = _ref.product,
    listPosition = _ref.listPosition,
    structure = _ref.structure;
  eventBuilder(eventName, {
    ad_id: product == null ? void 0 : (_product$ads = product.ads) == null ? void 0 : _product$ads.id,
    channel_id: adsConstants.channelId,
    content_id: product.id,
    content_position: product.position,
    module_position: listPosition,
    page_id: adsConstants.searchDefault.pageId,
    placement_id: adsConstants.searchDefault.placementId,
    showcase_id: adsConstants.searchDefault.showcaseId,
    user_id: structure == null ? void 0 : (_structure$cookies = structure.cookies) == null ? void 0 : _structure$cookies.customerId
  });
};
export default buildSearchEvent;