var decodeAdMetadata = function decodeAdMetadata(adMetadata) {
  try {
    return Buffer.from(adMetadata, 'base64').toString('UTF-8');
  } catch (e) {
    try {
      return btoa(adMetadata);
    } catch (error) {
      return null;
    }
  }
};
export default decodeAdMetadata;