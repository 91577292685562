import showcaseProductListView from './showcaseEvent/showcaseProductListView';
import showcaseProductListImpression from './showcaseEvent/showcaseProductListImpression';
import showcaseProductListClick from './showcaseEvent/showcaseProductListClick';
import searchProductListView from './searchEvent/searchProductListView';
import searchProductListImpression from './searchEvent/searchProductListImpression';
import searchProductListClick from './searchEvent/searchProductListClick';
var events = {
  'product:view': [searchProductListView, showcaseProductListView],
  'productlist:click': [showcaseProductListClick, searchProductListClick],
  'productlist:impression': [showcaseProductListImpression, searchProductListImpression]
};
export default events;