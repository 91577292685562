import adsConstants from '../../utils/adsConstants';
import extractAdId from '../../utils/extractAdId';
import eventBuilder from '../../utils/eventBuilder';
var showcaseProductListClick = function showcaseProductListClick() {
  var _window, _window$magaluads;
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    structure = _ref.structure;
  if (((_window = window) == null ? void 0 : (_window$magaluads = _window.magaluads) == null ? void 0 : _window$magaluads.track) && data.origin === 'bob' && data.trackingUrl) {
    var adId = extractAdId(data.trackingUrl);
    if (adId) {
      var _data$product, _data$product2, _data$showcase, _data$showcase2, _data$showcase3, _structure$cookies;
      eventBuilder('Click', {
        ad_id: adId,
        channel_id: adsConstants.channelId,
        content_id: data == null ? void 0 : (_data$product = data.product) == null ? void 0 : _data$product.id,
        content_position: data == null ? void 0 : (_data$product2 = data.product) == null ? void 0 : _data$product2.position,
        module_position: data == null ? void 0 : data.listPosition,
        page_id: data == null ? void 0 : (_data$showcase = data.showcase) == null ? void 0 : _data$showcase.pageId,
        placement_id: data == null ? void 0 : (_data$showcase2 = data.showcase) == null ? void 0 : _data$showcase2.placeId,
        showcase_id: data == null ? void 0 : (_data$showcase3 = data.showcase) == null ? void 0 : _data$showcase3.id,
        user_id: (_structure$cookies = structure.cookies) == null ? void 0 : _structure$cookies.customerId
      });
    }
  }
};
export default showcaseProductListClick;