import buildSearchEvent from './buildSearchEvent';
var searchProductListImpression = function searchProductListImpression() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    products = _ref.products,
    listPosition = _ref.listPosition;
  var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    structure = _ref2.structure;
  products.forEach(function (product) {
    if (product == null ? void 0 : product.ads) {
      buildSearchEvent({
        eventName: 'Impression',
        listPosition: listPosition,
        product: product,
        structure: structure
      });
    }
  });
};
export default searchProductListImpression;