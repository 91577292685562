import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
var productListClick = function productListClick() {
  var _window, _window$stewie;
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    structure = _ref.structure;
  if (((_window = window) == null ? void 0 : (_window$stewie = _window.stewie) == null ? void 0 : _window$stewie.api) && data.origin === 'doory') {
    var _structure$cookies, _structure$config, _data$category;
    stewie.api.uid(structure == null ? void 0 : (_structure$cookies = structure.cookies) == null ? void 0 : _structure$cookies.customerId);
    stewie.api.channel((structure == null ? void 0 : (_structure$config = structure.config) == null ? void 0 : _structure$config.stewieChannel) || '');
    stewie.api.clickedOnSearch({
      departmentId: data == null ? void 0 : (_data$category = data.category) == null ? void 0 : _data$category.id,
      productId: data == null ? void 0 : data.id
    });
    stewie.api.dispatch();
  }

  if (data.origin === 'bob' && data.trackingUrl) {
    var _data$trackingUrl$spl = data.trackingUrl.split('?'),
      _data$trackingUrl$spl2 = _slicedToArray(_data$trackingUrl$spl, 1),
      trackingUrl = _data$trackingUrl$spl2[0];
    document.createElement('img').src = trackingUrl;
  }
};
export default productListClick;